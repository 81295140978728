<template>
  <div :class="{ 'mdc-checkbox--disabled': disabled }" class="mdc-form-field">
    <div class="mdc-checkbox">
      <input
        :id="_uid"
        ref="native"
        :aria-labelledby="label"
        :checked="value"
        :disabled="disabled"
        :required="required"
        :tabindex="noTab ? -1 : 0"
        class="mdc-checkbox__native-control"
        type="checkbox"
        @change="$emit('input', $event.target.checked)"
      />
      <div class="mdc-checkbox__background">
        <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
          <path
            class="mdc-checkbox__checkmark-path"
            fill="none"
            stroke="white"
            d="M1.73,12.91 8.1,19.28 22.79,4.59"
          />
        </svg>
        <div class="mdc-checkbox__mixedmark" />
      </div>
    </div>
    <label :for="_uid">
      <slot>
        <span v-html="label" />
      </slot>
    </label>
  </div>
</template>

<script>
import { MDCFormField } from '@material/form-field';
import { MDCCheckbox } from '@material/checkbox';

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    noTab: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdcCheckbox: null,
    mdcFormField: null,
  }),
  mounted() {
    this.mdcFormField = new MDCFormField(this.$el);
    this.mdcCheckbox = new MDCCheckbox(this.$el.firstElementChild);
  },
  beforeDestroy() {
    this.mdcFormField.destroy();
    this.mdcCheckbox.destroy();
  },
};
</script>
